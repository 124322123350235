import { injectGlobal } from "styled-components";
import { default as VARIABLES } from "./../../../themes/styles/bases/variables";

injectGlobal`
  .section-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-zindex-lv1 {
      position: relative;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
    }

    &.is-zindex-lv2 {
      position: relative;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
    }

    &.is-zindex-lv3 {
      position: relative;
      z-index: ${VARIABLES.Z_INDEXS.LV_3};
    }

    &.is-zindex-lv4 {
      position: relative;
      z-index: ${VARIABLES.Z_INDEXS.LV_4};
    }

    &.is-zindex-lv5 {
      position: relative;
      z-index: ${VARIABLES.Z_INDEXS.LV_5};
    }

    &.is-cursor {
      cursor: pointer;
    }

    &.is-relative {
      position: relative;
    }

    &.is-absolute {
      position: absolute;
    }

    &.is-overflow-hidden {
      overflow: hidden;
    }

    &.is-upload-avatar {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 0;
      right: 0;
      width: auto;
    }

    &.is-hint-password {
      position: relative;
    }

    &.is-modal-header-button {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 0;
      right: 55px;
      width: auto;
    }

    &.is-notification {
      max-height: 320px;

      .loader {
        display: flex;
        justify-content: center;
        padding-bottom: 15px;
        font-size: 0;

        &:after {
          content: ' ';
          display: block;
          width: 30px;
          height: 18px;
          background-image: url(${require("../../../themes/images/shareds/loading.svg")});
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    &.is-google-viewer,
    &.is-browser-viewer {
      iframe {
        width: 100%;
        height: 100%;
      }
    }

    &.is-disabled-google-viewer-popup {
      position: relative;

      &:after {
        /* content: ' ';
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: 12px;
        right: 12px;
        display: block;
        width: 40px;
        height: 40px;
        background-color: ${VARIABLES.COLORS.GRAY_24}; */
      }
    }

    &.is-loading-google-viewer-popup {
      position: relative;
    }

    .is-loading-message-google-viewer-popup {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${VARIABLES.COLORS.OVERLAY_BLACK_1};
    }

    &.is-version-number {
      opacity: 0.35;
      position: fixed;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      bottom: 10px;
      left: 15px;

      &.is-version-number-right {
        right: 15px;
        left: auto;
      }
    }

    &.is-id-warranty {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 60px;
      right: 60px;
    }

    &.is-license-warranty {
      max-width: 1120px;
    }

    &.is-info-name {}

    &.is-short-warranty-label {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 100px;
      height: 100%;
      background-color: ${VARIABLES.COLORS.GRAY_8};
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &.is-generate-pdf-container {
      overflow: auto;
      position: fixed;
      z-index: ${VARIABLES.Z_INDEXS.LV_4};
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: ${VARIABLES.COLORS.WHITE};
    }

    &.is-hidden-for-generate-pdf {
      pointer-events: none;
      visibility: hidden;
    }

    &.is-break-all {
      word-break: break-all;
    }

    &.is-high-max-content {
      height: max-content;
    }

    /* Media queries
    ------------------------------- */
    &.is-upload-avatar {
      @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1365}) {
        position: static;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
      }
    }

    &.is-hint-password {
      @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
        border-left: none;
      }

      &:after {
        @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
          content: ' ';
          position: absolute;
          z-index: ${VARIABLES.Z_INDEXS.LV_1};
          top: 0;
          left: 45px;
          display: block;
          width: 1px;
          height: 100%;
          background-color: ${VARIABLES.COLORS.BLUE_15};
        }
      }
    }

    &.is-info-name {
      @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1441}) {
        max-width: 125px;
      }
    }

    &.is-info-name-short {
      @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1441}) {
        max-width: 95px;
      }
    }

    &.is-info-name-long {
      @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1441}) {
        max-width: 300px;
      }
    }

    &.is-year-warranty {
      @media (max-width: 1023px) {
        padding: 30px 15px;
      }
    }

    &.is-message-error {
      .text-base {
        color: ${VARIABLES.COLORS.ERROR};
      }
    }

    &.is-breakpoint-display-none {
      @media (max-width:  ${VARIABLES.BREAKPOINTS.BP_1023}) {
        display: none;
      }
    }
    
  }
`;
