import {
  injectGlobal
} from 'styled-components'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'

injectGlobal`
  .button-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-lightest-blue {
      background-color: ${VARIABLES.COLORS.BLUE_31};
    }

    &.is-light-blue {
      background-color: ${VARIABLES.COLORS.PRIMARY_1};
    }

    &.is-medium-blue {
      background-color: ${VARIABLES.COLORS.BLUE_21};
    }

    &.is-blue {
      background-color: ${VARIABLES.COLORS.PRIMARY_2};
    }

    &.is-red {
      background-color: ${VARIABLES.COLORS.RED_4};
    }

    &.is-green {
      background-color: ${VARIABLES.COLORS.GREEN_4};
    }

    &.is-light-green {
      background-color: ${VARIABLES.COLORS.GREEN_3};
    }

    &.is-brown {
      background-color: ${VARIABLES.COLORS.BROWN_2};
    }

    &.is-gray {
      background-color: ${VARIABLES.COLORS.GRAY_19};
    }

    &.is-gold {
      background-color: ${VARIABLES.COLORS.GOLD_1};
    }

    &.is-outline-blue {
      background-color: ${VARIABLES.COLORS.WHITE};
      border: 1px solid ${VARIABLES.COLORS.PRIMARY_2};

      .text-base {
        color: ${VARIABLES.COLORS.PRIMARY_2};
      }
    }

    &.is-outline-lightblue {
      background-color: ${VARIABLES.COLORS.WHITE};
      border: 1px solid ${VARIABLES.COLORS.PRIMARY_1};

      .text-base {
        color: ${VARIABLES.COLORS.PRIMARY_1};
      }
    }

    &.is-outline-lightestblue {
      border: 1px solid ${VARIABLES.COLORS.BLUE_16};

      .text-base {
        color: ${VARIABLES.COLORS.PRIMARY_2};
      }
    }

    &.is-text-small {
      .text-base {
        font-size: ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
      }
    }

    &.is-reset-password {
      background-color: ${VARIABLES.COLORS.BLUE_32};
      height: 40px;
      .text-base {
        color: ${VARIABLES.COLORS.WHITE};
        font-size: ${TYPOGRAPHYS.FIRST.MEDIUM.FS_16};
      }
    }

    &.is-outline-disabled {
      background-color: ${VARIABLES.COLORS.GRAY_1};
      border: 1px solid ${VARIABLES.COLORS.DISABLED};
      pointer-events: none;

      .text-base {
        color: ${VARIABLES.COLORS.DISABLED};
      }
    }

    /* Media queries
    ------------------------------- */
    &.is-breakpoint-mobile {
      @media (max-width: 530px) {
        width: auto;
        min-width: fit-content;
      }
    }
  }
`
